.draglayer-container {
	width: 400px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	margin-top: 10px;
}

.draglayer-container .layer {
	background: #108ee9;
	width: 150px;
	height: 150px;
	margin: 10px;
	color: white;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 40px;
}
