.cmspage-container {
	margin-top: 15px;
}

.cmspage-container .button-container {
	width: 100%;
	position: relative;
	text-align: right;
	padding: 3px;
	margin-bottom: 45px;
}

.cmspage-container .button-container .delete-icon {
	float: right;
	color: white;
	background: rgba(255, 0, 0, 0.65);
	border-radius: 12px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	right: 10px;
}
