.nestable-list-item {
	position: relative;
}

.nestable-list-item.is-dragging:before {
	padding: 0;
	background: #f2fbff;
	border: 1px dashed #b6bcbf;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	content: '';
}

.horizontal .nestable-list-item {
	width: 115px;
	display: block;
	float: left;
	margin-right: 4px;
}
