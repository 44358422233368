.cmsfilepicker-container {
}

.cmsfilepicker-container .topBar {
	background: whitesmoke;
	padding: 20px;
	display: flex;
}

.cmsfilepicker-images-container {
	height: 800px;
	overflow-y: scroll;
}

.cmsfilepicker-container .cms-thumbnail-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.cmsfilepicker-container .thumbnail {
	position: relative;
	width: 130px;
	height: 130px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	box-sizing: content-box;
	border: 5px solid transparent;
}
.cmsfilepicker-container .preview {
	background-color: #ccc;
	background-repeat: no-repeat;
	background-position: center;
	background-size: initial;
	-webkit-box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
	width: 130px;
	height: 130px;
}

.cmsfilepicker-container .thumbnail .folder {
	font-size: 110px;
	line-height: 110px;
	color: #e8c61c;
}
.cmsfilepicker-container .thumbnail.folder .name {
	display: block;
}

.cmsfilepicker-container .thumbnail.file.selected {
	border-color: #afafaf;
}
.cmsfilepicker-container .thumbnail.file .name {
	width: 130px;
	height: 24px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cmsfilepicker-container .thumbnail.file .delete {
	position: absolute;
	bottom: 27px;
	left: 6px;
	color: red;
	font-size: 20px;
	display: none;
}

.cmsfilepicker-container .thumbnail.file .pdf-icon {
	position: absolute;
	bottom: 28px;
	right: 5px;
	color: black;
	font-size: 35px;
	text-shadow: 4px 3px white;
}

.cmsfilepicker-container .thumbnail.file:hover .delete {
	display: block;
}
.cmsfilepicker-container .upload-button {
	width: 300px;
	height: 50px;
}
