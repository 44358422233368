.dropzone {
	background: yellow;
	opacity: 0.2;
}
.dropzone > div {
	margin: 50px;
	padding: 50px;
	background: green;
}
.dropzone.is-on-drop {
	background: red;
}
.overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 2.5em 0;
	background: rgba(0, 0, 0, 0.3);
	text-align: center;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.overlay .icon-container {
	background: black;
	border-radius: 10px;
	height: 200px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.21);
}
.overlay .icon {
	font-size: 30px;
}
