.dashboard-container {
	margin: 0 auto;
	margin-top: 120px;
	align-items: center;
	width: 760px;
	display: flex;
	flex-direction: column;
}

.dashboard-container .title {
	font-size: 30px;
	text-shadow: 1px 1px white;
	padding-right: 17px;
	color: black;
	text-align: center;
	align-items: center;
}

.dashboard-container .subtitle {
	font-size: 16px;
	color: gray;
}

.dashboard-container .steps {
	font-size: 18px;
	list-style-type: none;
}
.dashboard-container .steps li {
}
.dashboard-container .steps li .intro-title {
}
.dashboard-container .steps .count {
	border-radius: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #1890ff;
	height: 20px;
	width: 20px;
	font-size: 13px;
	color: white;
	margin-right: 13px;
	margin-bottom: 10px;
}
.dashboard-container .extra-steps {
	margin-left: 20px;
}
.dashboard-container .extra-steps li {
	font-size: 15px;
}
