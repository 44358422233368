.nieuwslist-item {
	display: block;
	line-height: 30px;
	margin: 5px 0;
	padding: 5px 10px;
	text-decoration: none;
	background: #4896ee;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	text-align: left;
	position: relative;
}

.nieuwslist-item.draggable:hover {
	cursor: move;
}

.nieuwslist-item.is-dragging {
	box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.49);
	border: 1px dashed #ebebeb;
	background-color: rgb(229, 234, 239);
}

.nieuwslist-item.inactive {
	background-color: rgb(210, 213, 216);
}

.nieuwslist-item.editmode {
	background: whitesmoke;
	cursor: auto;
	padding: 20px;
}

.nieuwslist-item-handle {
	color: white;
	display: flex;
	max-height: 30px;
}

.editmode .nieuwslist-item-handle {
	color: #000;
	margin-bottom: 20px;
}

.nieuwslist-item-title {
	margin-right: 5px;
	max-width: 120px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.nieuwslist-item-link {
	font-size: 0.85em;
	font-style: italic;
	max-width: 120px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
}

.nieuwslist-item-buttonpanel {
	position: absolute;
	right: 2%;
}

.nieuwslist-item-buttonpanel.hidden {
	display: none;
}

.nieuwslist-item-buttonpanel .fa {
	margin-left: 5px;
}

.nieuwslist-item-buttonpanel:hover {
	cursor: pointer;
}

/*EDIT PANEL */
.editpanel-enter {
	opacity: 0.01;
	max-height: 0;
	overflow: hidden;
	width: 100%;
}

.editpanel-enter.editpanel-enter-active {
	opacity: 1;
	transition: max-height 500ms ease-in, opacity 300ms ease-in;
	max-height: 1000px;
}

.editpanel-exit {
	opacity: 1;
	max-height: 1000px;
}

.editpanel-exit.editpanel-exit-active {
	opacity: 0.01;
	transition: max-height 500ms ease-in, opacity 300ms ease-in;
}

#formControlsTextarea {
	min-height: 400px;
}

.editpanel-news-wrap {
	display: flex;
}
.editpanel-news-inner {
	width: 100%;
}
.editpanel-news-image {
	max-width: 200px;
}
