.nestable-list-item-handle {
	display: block;
	height: 40px;
	line-height: 30px;
	margin: 5px 0;
	padding: 5px 10px;
	text-decoration: none;
	background: #4896ee;
	color: white;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	text-align: left;
	position: relative;
	cursor: pointer;
}

.nestable-list-item-handle:hover {
	background: #3f85d3;
	cursor: move;
	/*opacity: 0.7;*/
}

.nestable-list-item-handle.inactive {
	background-color: rgb(210, 213, 216);
}

.menu-delete {
	position: absolute;
	top: 7px;
	right: 5px;
	cursor: pointer;
}

.nestable-list-item-handle .set-inactive-icon {
	position: absolute;
	top: 7px;
	right: 35px;
	cursor: pointer;
}

.horizontal .nestable-list-item-handle .set-inactive-icon,
.nestable-list-item-handle .set-inactive-icon.hidden,
.menu-delete.hidden {
	display: none;
}

.handle-title {
	display: inline-block;
}

.linkpreview {
	display: inline-block;
	font-size: 0.85em;
	font-style: italic;
}

.horizontal .linkpreview {
	display: none;
}

.horizontal .handle-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 105px;
}

.horizontal .nestable-list-item-handle:hover .handle-title {
	width: 95px;
}
