.menuwizard {
	position: relative;
	font-size: 13px;
	line-height: 20px;
}

.linkpanel {
	width: 100%;
	padding: 20px;
}

.wizardoptions .ant-select-selector {
	min-width: 190px;
}