.action-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.action-header h2 {
	margin: 0;
}
.action-header .rounded-link {
	display: none;
	margin-left: 30px;
}
.theme-picker-container.active .rounded-link {
	display: block;
}
.action-header .action {
	cursor: pointer;
}
.action-header.active .rounded-link {
	opacity: 1;
}
.action-header .feedback.success {
	background-color: #5cb85c;
}
.action-header .feedback.info {
	background-color: #5bc0de;
}
.action-header .feedback.warning {
	background-color: #f0ad4e;
}
.action-header .feedback.danger {
	background-color: #e74444;
}
