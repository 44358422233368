.banner-tab-item {
	display: block;
	line-height: 30px;
	margin: 5px 0;
	padding: 5px 10px;
	text-decoration: none;
	background: #4896ee;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	text-align: left;
	position: relative;
}

.banner-tab-item.draggable:hover {
	cursor: move;
}

.banner-tab-item.is-dragging {
	box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.49);
	border: 1px dashed #ebebeb;
	background-color: rgb(229, 234, 239);
}

.banner-tab-item.editmode {
	background: linear-gradient(#4896ee -65%, #fff);
	cursor: auto;
}

.banner-tab-item .item-handle {
	color: white;
	font-weight: bold;
}

.banner-tab-item .item-title {
	display: inline-block;
	margin-right: 5px;
}

.banner-tab-item .item-link {
	display: inline-block;
	font-size: 0.85em;
	font-style: italic;
}

.banner-tab-item .item-buttonpanel {
	float: right;
}

.banner-tab-item .item-buttonpanel.hidden {
	display: none;
}

.banner-tab-item .item-buttonpanel .fa {
	margin-left: 5px;
}

.banner-tab-item .item-icon:hover {
	cursor: pointer;
}

/*EDIT PANEL */
.banner-tab-item .editpanel-enter {
	opacity: 0.01;
	max-height: 0;
	overflow: hidden;
	width: 100%;
}

.banner-tab-item .editpanel-enter.editpanel-enter-active {
	opacity: 1;
	transition: max-height 500ms ease-in, opacity 300ms ease-in;
	max-height: 1000px;
}

.banner-tab-item .editpanel-exit {
	opacity: 1;
	max-height: 1000px;
}

.banner-tab-item .editpanel-exit.editpanel-exit-active {
	opacity: 0.01;
	transition: max-height 500ms ease-in, opacity 300ms ease-in;
}
