.vertical-center-modal {
	text-align: center;
	white-space: nowrap;
}

.vertical-center-modal:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	width: 0;
}

.vertical-center-modal .ant-modal {
	display: inline-block;
	vertical-align: middle;
	top: 0;
	text-align: left;
}

.ant-modal-body {
	overflow: auto;
}

.ant-modal-body .ant-input {
	display: block;
	margin-bottom: 5px;
}

.ant-col-4 {
	text-align: center;
}

.ant-col-4 button {
	display: block;
	margin: 0 auto 20px;
}
