.ant-checkbox-wrapper {
	margin: 0 0 10px 0 !important;
}

.ant-card-body {
	padding-bottom: 0 !important;
}

.ant-card-bordered {
	margin-bottom: 15px !important;
}

.admin-settings .ant-tabs-tabpane:nth-child(4) .ant-col-md-12:first-child .ant-row:first-child {
	margin-top: 30px;
}
