.page-outline {
	padding: 15px;
	width: 100%;
	max-width: 1200px;
}

.anticon.support-icon {
	font-size: 18px;
	color: orange;
	line-height: 31px;
}

.anticon.support-icon:hover {
	cursor: pointer;
}

.ant-btn-primary.servicedesk-button {
	background-color: rgba(255, 147, 147, 1);
	border-color: rgba(255, 147, 147, 1);
}
