@import '~antd/dist/antd.min.css';

body {
	margin: 0;
	padding: 0;
	background: #f8f8f8;
}

a {
	color: #4f90df;
}

#root {
}

#main {
	height: 2000px;
}

/***********************************************
 bootstrap overrides for prototyping only!
***********************************************/
label {
	font-weight: normal;
}

.form-control {
	box-shadow: none;
	border-radius: 0;
}

/***********************************************
 react-select overrides for prototyping only!
***********************************************/
.Select-control {
	border-radius: 0 !important;
	height: 30px !important;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
	height: 30px;
}

.Select-input {
	height: 30px !important;
}

/***********************************************
 ant.d overrides
***********************************************/

.ant-form-item-with-help {
	margin-bottom: 8px;
}

.ant-form-item-label {
	line-height: 26px;
}
