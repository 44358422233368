.widgetpicker {
	background: white;
	padding: 20px;
	top: -18px;
	border-left: 1px solid #eaeaea;
}

.widgetpicker .widgetlist {
	width: 370px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	margin-top: 10px;
}

.widgetpicker .widgetlist .widget {
	background: #108ee9;
	width: 150px;
	height: 150px;
	margin: 10px;
	color: white;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 40px;
}
.widgetpicker .widgetlist .widget.disabled {
	background: #ccc;
}

/**************************************************
 Overriding ant styles for scrollbar in widgetlist..
**************************************************/

.widgetpicker .ant-tabs {
	display: flex;
	flex-direction: column;
}
.widgetpicker .ant-tabs-tabpane {
	display: flex;
	flex-direction: column;
}

.widgetpicker .ant-tabs-bar {
	/*display: none;*/
}

.widgetpicker .icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.widgetpicker .icon-title {
	font-size: 14px;
	text-align: center;
}
