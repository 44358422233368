.contentblok {
	width: 100%;
	min-height: 150px;
	margin-bottom: 10px;
	border: 1px dashed #dadada;
	border-radius: 3px;
	padding: 10px;
	background-color: #fff;
}

.contentblok:hover {
	background-color: whitesmoke;
	cursor: pointer;
}

.contentblok .plus {
	height: 150px;
	width: 100%;
	font-size: 18px;
	justify-content: center;
	align-items: center;
	display: flex;
	order: 1;
	cursor: pointer;
	flex-direction: column;
	vertical-align: top;
}
.contentblok .plus .icon {
	color: #4f90df;
	font-size: 28px;
}
.contentblok .plus .label {
	color: #d4d4d4;
	font-size: 13px;
}

.contentblok .title {
	font-size: 16px;
	font-weight: bold;
}
.contentblok .subtitle {
	font-size: 14px;
	color: gray;
}

.contentblok .infopanel {
	position: relative;
}

.contentblok .delete-icon {
	float: right;
	color: white;
	background: rgba(255, 0, 0, 0.65);
	border-radius: 12px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	right: 0px;
}
