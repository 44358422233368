.parnerlogowizard-images-container {
	height: 800px;
	overflow-y: scroll;
}

.parnerlogowizard-container .thumbmnail-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.parnerlogowizard-container .thumbmnail {
	width: 150px;
	height: 150px;
	background-color: #ccc;
	margin: 10px;
	cursor: pointer;
	border: 1px solid rgb(229, 229, 229);
	background-repeat: no-repeat;
	background-position: center;
	background-size: initial;
}
.parnerlogowizard-container .upload-button {
	width: 300px;
	height: 50px;
}
