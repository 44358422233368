.theme-wrapper {
	background-color: #fff;
	padding: 40px;
	margin: 40px auto;
	box-shadow: 0 2px 8px rgba(211, 209, 209, 0.75);
	max-width: 1000px;
}
.theme-wrapper .inner-title {
	margin-bottom: 20px;
}
.theme-picker-container {
	position: relative;
}
.theme-picker-container .arrowPoshPicker {
	position: absolute;
	top: 150px;
	left: 250px;
}
.theme-picker-container .arrowPoshPicker p {
	font-family: 'Nanum Brush Script', cursive;
	font-size: 30px;
	line-height: 30px;
}
.theme-picker-container.active .arrowPoshPicker {
	display: none;
}
.theme-picker-container .arrowPoshPicker img {
	transform: scaleX(-1) rotate(30deg);
}

/*********************************************
 posh pickers
********************************************/
.picker {
	transition: width 300ms;
	width: 180px;
}
.picker.hideMe {
	width: 0px;
}
.picker.showMe {
	width: 100%;
}

/*********************************************
 ActionHeader
********************************************/
.theme-picker-container > .title {
	display: inline-block;
}

.theme-picker-container .cancel-link {
	opacity: 0;
	margin-left: 30px;
	transition: opacity 100ms 500ms;
}
.theme-picker-container.active .cancel-link {
	opacity: 1;
	background: #4f90df;
	border-radius: 10px;
	padding: 3px 8px;
	font-size: 13px;
	color: white;
	text-decoration: none;
}

/*********************************************
 Colors
********************************************/
.color-subsection-container {
	display: flex;
	flex-direction: row;
}
.color-subsection {
	margin-right: 100px;
}

/*********************************************
 ColorPicker
********************************************/
.color-subsection-container,
.color-subsection-container .ant-tabs-tabpane-active {
	min-height: 635px;
}
.color-subsection-container > .ant-tabs {
	width: 100%;
}
