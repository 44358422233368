.thumbnail-container {
	display: inline-block;
	margin: 10px;
	width: 150px;
	height: 150px;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: white;
	background-position: center center;
	transition: all 200ms;
	box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
}

.thumbnail-container:hover {
	box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.09);
	border-color: transparent;
}
