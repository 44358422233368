.dnd {
	box-shadow: 0 0 40px -3px rgba(0, 0, 0, 0.14);
	width: 900px;
	box-sizing: content-box;
	transition: height 300ms;
}
.dnd .block {
	width: 50px;
	height: 50px;
	position: absolute;
	transition: all 300ms;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
	border: 1px solid white;
}

.dnd .grid {
}
.dnd .grid .row {
	margin: 0; /* override bootstrap conflicting className */
}
.dnd .grid .col {
	position: absolute;
	border-right: 1px solid rgba(255, 255, 255, 0.27);
	border-bottom: 1px solid white;
	border-color: rgba(53, 128, 183, 0.17);
}
