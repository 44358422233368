.partnerlogo-list {
	display: flex;
	flex-wrap: wrap;
}

.partnerlogo-list .plus {
	height: 150px;
	width: 150px;
	background: whitesmoke;
	font-size: 30px;
	justify-content: center;
	align-items: center;
	margin: 10px;
	display: inline-flex;
	order: 1;
	cursor: pointer;
	color: #4f90df;
	vertical-align: top;
}

.partnerlogo-list .sortable-list-item {
	display: inline-block;
	position: relative;
}

.partnerlogo-list-container .thumbnail-container {
	background-color: #ccc;
	background-repeat: no-repeat;
	background-position: center;
	background-size: initial;
}

.sortable-list-item .thumbnail-container {
	cursor: move;
}

.partnerlogo-list .sortable-list-item.is-dragging .thumbnail-container {
	box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.49);
	border: 1px dashed #ebebeb;
	background-color: rgb(229, 234, 239);
}

.partnerlogo-list-container .delete-icon {
	float: right;
	margin: 7px;
	color: white;
	background: rgba(255, 0, 0, 0.65);
	border-radius: 12px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}

.partnerlogo-panel {
}

.partnerlogo-panel .title {
	margin-bottom: 20px;
}

.partnerlogo-panel .wizardbuttons {
	margin-bottom: 15px;
}

.partnerlogo-panel .button-row {
	margin-bottom: 15px;
	margin-top: 15px;
}

.partnerlogo-panel .button-row .ant-btn {
	margin-right: 15px;
}

.icon-thumbnail {
	position: relative;
	display: inline-block;
}
