/* showemployee */
.showemployee {
	width: 150px;
	height: 150px;
	position: relative;
	cursor: move;
	margin: 8px;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
	transition: transform 200ms;
}
.showemployee:hover {
	transform: scale(1.05);
}

.showemployee .picture {
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	height: 100%;
	width: 100%;
}

.showemployee .name {
	z-index: 1;
	position: absolute;
	top: 65%;
	width: 80%;
	transform: translateX(-50%);
	left: 50%;
	text-align: center;
	box-sizing: border-box;
	font-size: 12px;
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
	border-radius: 3px;
	padding: 2px 0;
	color: #1890ff;
}
