.basenav > .container {
	padding-left: 0;
	margin-left: 20px;
}
.basenav.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	background: transparent;
}
.basenav.navbar-default {
	border-radius: 0;
	font-size: 17px;
	background: white;
	margin-bottom: 0;
}

.basenav.navbar-default .navbar-nav > li > a {
	padding: 20px 20px 18px 20px;
}

.basenav.navbar-default .navbar-nav > li {
	border-bottom: 4px solid transparent;
	transition: border-bottom 0.25s ease-in-out;
}

.basenav.navbar-default .navbar-nav > li.active,
.basenav.navbar-default .navbar-nav > li:hover {
	border-bottom: 4px solid #007dc1;
}

.basenav.navbar-default .navbar-header {
	width: 200px;
}

.basenav.navbar-default .navbar-brand {
	width: 100%;
}

.basenav.navbar-default .navbar-nav.navbar-right li a span {
	transition: opacity 0.5s linear;
}

.basenav.navbar-default .navbar-nav.navbar-right li a span.invisible-opacity {
	opacity: 0;
}
