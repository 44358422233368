.menubuilder-wrapper {
	position: relative;
	display: block;
	margin: 0 0 15px 0;
	list-style: none;
	font-size: 13px;
	line-height: 20px;
	padding: 20px;
}

.menubuilder-buttons {
	text-align: right;
	width: 100%;
	margin-bottom: 5px;
}

.menubuilder-buttons .btn-horizontal .icon {
	transform: rotate(90deg);
}
