/* Helpers */
.employeeswidget .clearfix {
	overflow: auto;
}

.employeeswidget .clearfix::after {
	content: '';
	clear: both;
	display: table;
}

.employeeswidget .sortable-list-item {
	display: inline-block;
}

/* add employee */
.employeeswidget .addemployee {
	width: 150px;
	height: 150px;
	background-color: whitesmoke;
	position: relative;
	cursor: pointer;
	float: left;
	margin: 10px;
	border-radius: 50%;
}

.employeeswidget .addemployee span {
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	color: #4f90df;
	font-size: 30px;
}

.employeeswidget .delete-employee {
	border: 1px solid red;
	z-index: 2;
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	right: 13%;
	top: 14%;
	background-color: red;
	color: #fff;
	text-align: center;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.employeeswidget .visible {
	opacity: 1;
}

.employeeswidget .delete-employee .fa-lg {
	vertical-align: -3%;
}
