.nestable-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0 0 1px 0;
	list-style: none;
}

.nestable-list .nestable-list {
	padding-left: 30px;
}

.addNewButton {
	width: 100%;
}
.is-dragging .addNewButton {
	display: none;
}

.horizontal .nestable-list .nestable-list {
	padding: 0px;
	min-height: 30px;
	color: #727272;
}

.horizontal .is-dragging .empty {
	display: none;
}
