.headerbanner-list {
	display: flex;
	flex-wrap: wrap;
}

.headerbanner-list .plus {
	height: 150px;
	width: 150px;
	background: whitesmoke;
	font-size: 30px;
	justify-content: center;
	align-items: center;
	margin: 10px;
	display: inline-flex;
	order: 1;
	cursor: pointer;
	color: #4f90df;
	vertical-align: top;
}

.headerbanner-list .sortable-list-item {
	display: inline-block;
	position: relative;
}

.headerbanner-listt .sortable-list-item.is-dragging .thumbnail-container {
	box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.49);
	border: 1px dashed #ebebeb;
	background-color: rgb(229, 234, 239);
}

.headerbanner-list .icon {
	float: right;
	margin: 7px;
	color: white;
	background: rgba(255, 0, 0, 0.65);
	border-radius: 12px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}
