.theme-carousel {
	position: relative;
	margin-top: 20px;
}

.ant-carousel .slick-track .slick-slide {
	text-align: center;
	overflow: hidden;
}

.ant-carousel .thumbnail-wrapper {
	display: block;
	cursor: pointer;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.07);
	height: 165px;
	position: relative;
	padding: 8px;
	background-color: #fff;
	margin: 15px 10px;
	z-index: 1;
}

.thumbnail-inner {
	display: block;
	cursor: pointer;
	position: absolute;
	margin: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 1;
}

.thumbnail-wrapper {
	position: relative;
	background-color: #f5f5f5d9;
	transition: 0.1s all ease-in-out;
	border: 5px solid white;
}
.thumbnail-wrapper.selected {
	border: 5px solid #40a9ff;
}

.thumbnail-tooltip {
	width: 100%;
}

.ant-tooltip-inner {
	max-width: 450px !important;
}

.ant-carousel .thumbnail-wrapper:hover {
	transform: scale(1.05);
}

.ant-carousel .label {
	padding: 4px 10px;
	border-radius: 6px;
	font-size: 14px;
	background: #00000008;
	color: #00000082;
	max-width: 100px;
	margin: 20px auto;
}

.ant-carousel .slick-dots li button {
	background: #000 !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	background: black !important;
}

.carousel-item {
	padding: 0 3px;
}

.link-arrowed {
	color: #909090;
	position: absolute;
	top: 30%;
	cursor: pointer;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	z-index: 1;
}

.link-arrowed.link-left {
	left: 10px;
	transform: rotate(180deg);
}

.link-arrowed.link-right {
	right: 10px;
}

.link-arrowed .arrow-icon {
	position: relative;
	top: -1px;
	transition: transform 0.3s ease;
	vertical-align: middle;
}

.link-arrowed .arrow-icon-circle {
	transition: stroke-dashoffset 0.3s ease;
	stroke-dasharray: 95;
	stroke-dashoffset: 95;
}

.link-arrowed:hover .arrow-icon {
	transform: translate3d(5px, 0, 0);
}

.link-arrowed:hover .arrow-icon-circle {
	stroke-dashoffset: 0;
}

.slick-list {
	width: 89%;
	margin: 0 auto !important;
}
