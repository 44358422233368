.page-selector {
}

.page-selector .url,
.page-selector-ant-component li .url {
	margin-left: 10px;
	display: inline-block;
	color: gray;
	font-size: 12px;
}
