.fileinput-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 40px;
	width: 150px;
	background: #4f90df;
}
.fileinput {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.upload-icon {
	color: white;
}
