.homepage {
	margin-top: 50px;
}
.homepage .homepage-dnd {
}
.homepage .widgets-container {
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding-left: -200px;
}
@media (max-width: 1400px) {
	.homepage .widgets-container {
		width: 70%;
	}
}
@media (max-width: 1060px) {
	.homepage .widgets-container {
		width: 60%;
	}
}

.homepage-widgetpicker {
	right: 0;
	bottom: 0;
	width: 300px;
	display: flex;
	flex-direction: column;
	position: fixed;
}

/**************************************************
 Drag & Drop
**************************************************/
.homepage .dnd .widget {
	cursor: move;
	background: #108ee9;
	transition: all 200ms;
}

.homepage .dnd .widget .icon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.homepage .dnd .widget .icon-title {
	text-align: center;
}

.homepage .dnd .widget.active {
	background: #39acff;
	box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.4);
}

.homepage .dnd .widget .trash-icon {
	display: none;
	cursor: pointer;
	padding: 10px;
}
.homepage .dnd .widget:hover .trash-icon {
	display: block;
	position: absolute;
	top: 10px;
	left: 10px;
}
